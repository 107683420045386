





































import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EmailType from '@/utils/enums/EmailType';
import { namespace } from 'vuex-class';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import AuthPageGuard from '@/guards/AuthPageGuard';

const toastStore = namespace('ToastStore');
const authenticationStore = namespace('AuthenticationStore');

@Component({
  components: {
    ButtonComponent,
  },
  beforeRouteEnter: AuthPageGuard.beforeEnter,
})
export default class SignInEmailSent extends Vue {
  @authenticationStore.State
  private readonly signInEmailState!: EmailType | null;

  @authenticationStore.State
  private readonly authEmail!: string | null;

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  @authenticationStore.Action
  private triggerRecovery!: (payload: { email: string }) => void;

  @authenticationStore.Action
  private magicLink!: (payload: {
    username: string;
  }) => Promise<{ success: boolean }>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private EmailType = EmailType;

  created(): void {
    if (!this.signInEmailState) {
      this.$router.push({ name: 'sign-in' });
    }
  }

  onButtonClick(): void {
    if (this.signInEmailState && [EmailType.PASSWORD_RESET, EmailType.EMAIL_LINK].includes(this.signInEmailState)) {
      this.$router.push({ name: 'sign-in' });
    }
    if (this.signInEmailState === EmailType.WELCOME_EMAIL) {
      this.triggerRecovery({
        email: this.authEmail as string,
      });
    }
  }

  onSendAgainClick(): void {
    if (this.signInEmailState === EmailType.PASSWORD_RESET) {
      this.triggerRecovery({
        email: this.authEmail as string,
      });
    }
    if (this.signInEmailState === EmailType.EMAIL_LINK) {
      this.magicLink({
        username: this.authEmail as string,
      });
    }
    this.addNewAction(
      { type: ToastActionType.EMAIL_SENT },
    );
  }

  onClickContinue(): void {
    this.$router.push({ path: '/' });
  }
}
